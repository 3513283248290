import { Unit } from '@/settings/domain/unit/Unit';

export class Packaging {
  codeRef: string;
  id: number;
  isGeneral: boolean;
  isPuschase: boolean;
  isSale: boolean;
  numberUnits: number;
  supplierCode: any = [];
  unit: Unit;

  public constructor() {
    this.codeRef = '';
    this.id = 0;
    this.isGeneral = false;
    this.isPuschase = false;
    this.isSale = false;
    this.numberUnits = 0;
    this.unit = new Unit();
  }
}
