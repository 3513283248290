
import Component from 'vue-class-component';
import Vue from 'vue';
import { selectedLocale } from '@/core/plugins/i18n';
import { ValidationObserver } from 'vee-validate';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { SearchTerritoriesByQueryParameter } from '@/settings/application/uses_cases/views/territories/SearchTerritoriesByQueryParameter';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { SearchByCountryStateAndCity } from '@/settings/application/uses_cases/zipCod/search/SearchCitiesByCountryAndStateActive';
import { CurrencyFindAll } from '@/settings/application/uses_cases/currency/search/CurrencyFindAll';
import { Currency } from '@/settings/domain/currency/Currency';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { Company } from '@/settings/domain/company/Company';
import CalculateServiceN from '@/courier/infrastructure/ui/components/calculateService/CalculateServiceN.vue';
import { CurrencyFactorFindRateByDate } from '@/settings/application/uses_cases/currencyfactor/search/CurrencyFactorFindRateByDate';
import { CreateOrder } from '@/courier/application/uses_cases/order/create/CreateOrder';
import { CourierOrder } from '@/courier/domain/CourierOrder/CourierOrder';
import { Prop } from 'vue-property-decorator';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { FindCommodityTypeByStatus } from '@/freight/application/commodity_type/search/FindCommodityTypesByStatus';
import { StateManager } from '@/core/statemanager/StateManager';
import { PriceList } from '@/settings/domain/priceList/PriceList';
import { VolumeFindAll } from '@/settings/application/uses_cases/volume/search/VolumeFindAll';
import { WeightFindAll } from '@/settings/application/uses_cases/weight/search/WeightFindAll';
import PricelistOrder from '../pricelistOrder/PricelistOrder.vue';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { ZipCode } from '@/settings/domain/ZipCode/ZipCode';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import SearchTerritories from '@/general/infrastructure/shared/SearchTerritories.vue';
import { SearchTerritoryByQueryDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByQueryDescription';
import { PricingZoneFindByOriginAndDestination } from '@/settings/application/uses_cases/PricingZone/search/PricingZoneFindByOriginAndDestination';
import { RequestPricelistPayload } from '@/core/helper/RequestPricelistPayload';
import { EntityFindById } from '@/settings/application/uses_cases/entity/search/EntityFindById';

@Component({
  name: 'CreateOrderN',
  components: { CalculateServiceN, PricelistOrder, SearchTerritories }
})
export default class CreateOrderN extends Vue {
  //TYPES
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly searchCustomerByDescriptionLike!: EntityFindFilter;
  @Inject(TYPES.API_ENTITY_FIND_BY_ID)
  readonly entityFindById!: EntityFindById;
  @Inject(TYPES.VIEW_FIND_TERRITORIES)
  readonly searchTerritoriesByQueryParameter!: SearchTerritoriesByQueryParameter;
  @Inject(TYPES.API_VIEW_FIND_TERRITORIES_BY_QUERY)
  readonly searchInAllTerritories!: SearchTerritoryByQueryDescription;
  @Inject(TYPES.ZIPCODE_SEARCH_BY_COUNTRY_STATE_CITY)
  readonly searchByCountryStateAndCity!: SearchByCountryStateAndCity;
  @Inject(TYPES.FINDALL_CURRENCY)
  readonly findAllCurrency!: CurrencyFindAll;
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly companyFindById!: CompanyFindById;
  @Inject(TYPES.FINDRATEBYDATE_CURRENCY_FACTOR)
  readonly currencyFactorFindRateByDate!: CurrencyFactorFindRateByDate;
  @Inject(TYPES.CREATE_ORDER)
  readonly createOrder!: CreateOrder;
  @Inject(TYPES.COMMODITY_TYPE_FIND_BY_STATUS)
  readonly findActiveCommodity!: FindCommodityTypeByStatus;
  @Inject(TYPES.STATE_MANAGER)
  readonly stateManager!: StateManager;
  @Inject(TYPES.FINDALL_VOLUME)
  readonly findAllVolumeUnits!: VolumeFindAll;
  @Inject(TYPES.FINDALL_WEIGHT)
  readonly findAllWeightUnits!: WeightFindAll;
  @Inject(TYPES.PRICING_ZONE_FIND_BY_ORIGIN_AND_DESTINATION)
  readonly findPricelist!: PricingZoneFindByOriginAndDestination;

  //FORM
  form: CourierOrder = new CourierOrder();
  //SELECTS
  selectedOrigin: Territories | null = null;
  selectedOriginZipCode: ZipCode | null = null;
  selectedCustomer: Entity | null = new Entity();
  selectedCurrency: Currency | null = null;
  selectedDestination: Territories | null = null;
  //LISTS
  origin: Territories[] = [];
  preEntities = [] as any;
  destination: Territories[] = [];
  zipCodesOrigin = [] as any;
  zipCodesDestination = [] as any;
  currencies: Currency[] = [];
  dataCompany: Company[] = [];
  //OTHER DATA
  companyLocalCurrency = '';
  calculatedQuotation = null as any;
  dataRoute: any;
  selectedLocales = selectedLocale;
  pricingLevel = '';
  clientId = localStorage.getItem('customerId');
  optionsTime: any = [
    { value: '8:00am a 12:00pm', text: '8:00am a 12:00pm' },
    { value: '13:00pm a 17:00pm', text: '13:00pm a 17:00pm' }
  ];
  //PROPS
  @Prop()
  readonly orderType!: number;
  //BOOLEAN DATA
  checkedAdressee = false;
  disableCurrency = false;
  isLoading = false;
  //REFERENCIAS
  $refs!: {
    mdValidateTrmOrder: HTMLFormElement;
    formWizardOrder: HTMLFormElement;
    modalPackageOption: HTMLFormElement;
    orderCustomerCountry: HTMLFormElement;
    registerClient: HTMLFormElement;
    orderCustomerId: HTMLInputElement;
    orderDestinationAddress: HTMLInputElement;
    firstStep: InstanceType<typeof ValidationObserver>;
    calculateSrv: CalculateServiceN;
    pricelistOrder: PricelistOrder;
  };

  //INICIO
  async mounted() {
    await this.fetchRequiredData();
  }

  //SEARCH INTERVAL VARIABLES
  timeout: any;
  searchInterval = 600;

  //METHODS

  async fetchRequiredData() {
    await this.searchAllCurrency();
    await this.getCurrencyData();
    await this.weightFindAllList();
    await this.volumeFindAllList();
    await this.findCustomerById();
  }

  async validateStepOne() {
    const payload: RequestPricelistPayload = {
      origin: this.selectedOrigin,
      destination: this.selectedDestination,
      level: this.pricingLevel
    };

    const validate = await this.$refs.firstStep.validate();
    if (!validate) {
      this.$swal({ title: `${this.$t('general.fillAllFields')}`, icon: 'warning' });
      return false;
    }

    const res = await this.findPricelist.execute(payload);
    const invalidRes = res.volume.length < 1 && res.weight.length < 1;
    if (invalidRes && this.orderType != 3) {
      this.$swal(`${this.$t('general.noPricelist')}`, ``, 'warning');
      return false;
    }

    this.stateManager.state.dataPriceList = [];
    this.stateManager.state.dataPriceList.push(res.volume, res.weight);
    this.stateManager.state.dataPriceList = this.stateManager.state.dataPriceList.reduce(
      (acc, item) => acc.concat(item),
      []
    );

    await this.getCommodityActive();

    if (this.orderType != 3) {
      const volumeListCheck = this.stateManager.state.dataPriceList.map(item => item.volumeUnit);
      const weightListCheck = this.stateManager.state.dataPriceList.map(item => item.weightUnit);
      const commodityListCheck = this.stateManager.state.dataPriceList.map(item => item.commodity?.id);

      this.stateManager.state.dataVolume = this.stateManager.state.dataVolume.filter((item: any) =>
        volumeListCheck.includes(item.id)
      );
      this.stateManager.state.dataWeight = this.stateManager.state.dataWeight.filter((item: any) =>
        weightListCheck.includes(item.id)
      );
      this.stateManager.state.dataCommodityTypes = this.stateManager.state.dataCommodityTypes.filter(item =>
        commodityListCheck.includes(item.id)
      );
    }
    this.form.routeId =
      this.stateManager.state.dataPriceList.length > 0 ? this.stateManager.state.dataPriceList[0].routeId : 0;

    return true;
  }

  executePricingCall() {
    if (this.form.lines.length < 1) {
      this.$swal(`${this.$t('general.warning')}`, `${this.$t('general.errorPackagesNumber')}`, 'warning');
      return false;
    }
    return this.$refs.calculateSrv.calculatePricing();
  }

  verCosas(cosa: any) {
    // eslint-disable-next-line no-console
    console.log(cosa);
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  translateCol(colName: any) {
    return this.$i18n.t('general.' + colName);
  }

  idLabel(customer: Entity) {
    return `${customer.id} - ${customer.fullName}`;
  }

  async findCustomerById() {
    const customerCode = localStorage.getItem('customerCode') as any;
    this.isLoading = true;

    await this.entityFindById
      .execute(customerCode)
      .then((response: any) => {
        // Origen
        this.selectedCustomer = response;
        this.preEntities = [response];
        this.form.originAddress.description = response.address.description;
        this.selectedOrigin = response.address.selectTerritorie;
        this.form.customerCode = response.code;
        this.form.customerId = response.id;

        // Destino
        this.form.destination.customerId = response.id;
        this.form.destination.fullName = response.fullName;

        this.isLoading = false;
      })
      .catch(error => {
        this.preEntities = [];
        this.isLoading = false;
        throw new Error(`${error}`);
      });
  }

  findTerritriesFromView(query: string, input: string) {
    clearTimeout(this.timeout);
    if (query.length < 1) return;
    this.timeout = setTimeout(async () => {
      if (query.length >= 3) {
        this.isLoading = true;
        await this.searchTerritoriesByQueryParameter
          .execute(query.toUpperCase())
          .then((response: any) => {
            this.isLoading = false;
            input === 'origin' ? (this.origin = response) : (this.destination = response);
          })
          .catch(error => {
            this.isLoading = false;
            input === 'origin' ? (this.origin = []) : (this.destination = []);
            throw new Error(`${error}`);
          });
      }
      clearTimeout(this.timeout);
    }, this.searchInterval);
  }

  async searchAllCurrency() {
    await this.findAllCurrency
      .execute()
      .then(response => {
        this.currencies = response;
      })
      .catch(error => {
        throw new Error(`${error}`);
      });
  }

  async getCurrencyData() {
    const companyId = localStorage.getItem('businessId') as string;
    const res: any = await this.companyFindById.execute(companyId);
    this.dataCompany.push(res);
    this.companyLocalCurrency = res.localCurrency;
    this.selectedCurrency = this.currencies.filter((item: Currency) => {
      return this.companyLocalCurrency == item.currencyCode;
    })[0];
    this.form.currency = this.selectedCurrency.currencyCode;
    this.disableCurrency = !res.multiCurrency;
    this.pricingLevel = res.zonePricingLevel;
    if (res.multiCurrency) {
      this.$nextTick(() => this.getCurrencyFactorDate());
    }
  }

  handdleDestinationMultiselect(input: Territories) {
    this.form.destination.addressLine.country = input.country;
    this.form.destination.addressLine.countryName = input.countryName;
    this.form.destination.addressLine.state = input.state;
    this.form.destination.addressLine.stateName = input.stateName;
    this.form.destination.addressLine.city = input.city;
    this.form.destination.addressLine.cityName = input.cityName;
  }

  pricingFactorSelected(objFactor: any) {
    this.calculatedQuotation = objFactor;
    if (this.calculatedQuotation) {
      this.$refs.formWizardOrder.nextTab();
    }
  }

  getCurrencyFactorDate() {
    if (this.selectedCurrency?.currencyCode !== this.companyLocalCurrency) {
      const date = new Date();
      const isoDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
      const payload: any = {
        currency: this.companyLocalCurrency,
        date: isoDateTime.substring(0, 10)
      };
      this.currencyFactorFindRateByDate
        .execute(payload)
        .then((response: any) => {
          this.form.rateValue = response['rate'];
        })
        .catch((err: any) => {
          return err;
        })
        .finally(() => {
          if (this.form.rateValue > 0) {
            this.$nextTick(() => this.$refs['mdValidateTrmOrder'].hide());
          } else {
            this.$nextTick(() => this.$refs['mdValidateTrmOrder'].show());
          }
        });
    }
  }

  //evento de guardado de la orden
  async shippingRegister() {
    // this.form.destination.addressLine.zipCode = this.form.destination.addressLine.zipCode.description;
    const payload: any = {
      ...this.form,
      module: 'courier',
      date: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString(),
      id: 0,
      approved: true,
      warehouse: '0000',
      status: null,
      typeOrder: this.orderType == 1 ? 'PR' : this.orderType == 2 ? 'CT' : 'QR',
      sw: this.orderType != 3 ? 1 : 2
    };
    payload.lines.map((item: any) => {
      delete item.weightPriceLists;
      delete item.volumePriceLists;
      delete item.priceListCalcW;
      delete item.priceListCalcV;
      delete item.selectedProvider;
    });
    delete payload['palletFactor'];
    delete payload['rates'];
    delete payload['palletFactorValue'];
    delete payload['palletFactorValue'];
    this.isLoading = true;
    try {
      const res = await this.createOrder.execute(payload);
      if (res.code === 404) {
        this.$swal({
          title: `${this.$t('general.warning')}`,
          text: `${this.$t('general.noRoutePlaning')}`,
          icon: 'warning'
        });
        this.isLoading = false;
        return;
      }
      if (!('error' in res)) {
        this.clearForm();
        this.fetchRequiredData();
      }
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
    this.isLoading = false;
    this.$router.push('/courier/orderhistory');
    return true;
  }

  async getCommodityActive() {
    await this.findActiveCommodity
      .execute(true)
      .then((response: CommodityType[]) => {
        this.stateManager.state.dataCommodityTypes = response;
      })
      .catch(error => {
        this.stateManager.state.dataCommodityTypes = [];
        throw new Error(error);
      });
  }

  searchUnitInPricelist(unit: string) {
    const isValid = false;
    this.stateManager.state.dataPriceList.map((item: PriceList) => {
      return item.volumeUnitCode == unit;
    });
    return isValid;
  }

  async volumeFindAllList() {
    await this.findAllVolumeUnits
      .execute()
      .then((response: any) => {
        this.stateManager.state.dataVolume = response;
      })
      .catch(error => {
        throw new Error(error);
      });
  }

  async weightFindAllList() {
    await this.findAllWeightUnits
      .execute()
      .then((response: any) => {
        this.stateManager.state.dataWeight = response;
      })
      .catch(error => {
        throw new Error(error);
      });
  }

  handdleOriginChange(input: any) {
    this.form.originAddress = {
      ...this.form.originAddress,
      ...input
    };
  }

  handdleOriginZipCode(input: ZipCode) {
    this.form.originAddress.zipCode = input.description;
  }

  handdleChangeCustomerData(input: Entity) {
    this.form.originAddress.description = input.address.description;
    this.form.customerCode = input.code;
    this.form.customerId = input.id;
  }

  handdleChangeCurrency(input: Currency) {
    this.form.currency = input.currencyCode;
  }

  async checkSelectedProvider() {
    return await this.$refs.pricelistOrder.verificar();
  }

  clearForm() {
    this.$refs.formWizardOrder.reset();
    this.selectedOrigin = null;
    this.selectedOriginZipCode = null;
    this.selectedCustomer = new Entity();
    this.selectedDestination = null;
    this.form = new CourierOrder();
  }

  createQuotationRequest(form: any) {
    // eslint-disable-next-line no-console
    console.log(form);
    throw new Error('Not implemented method');
  }

  searchTerritories(query: string, input: any) {
    clearTimeout(this.timeout);
    if (query.length < 1) return;
    this.timeout = setTimeout(async () => {
      if (query.length >= 3) {
        this.isLoading = true;
        await this.searchInAllTerritories
          .execute(query.toUpperCase())
          .then((response: any) => {
            this.isLoading = false;
            input === 'origin' ? (this.origin = response) : (this.destination = response);
          })
          .catch(error => {
            this.isLoading = false;
            input === 'origin' ? (this.origin = []) : (this.destination = []);
            throw new Error(`${error}`);
          });
      }
      clearTimeout(this.timeout);
    }, this.searchInterval);
  }

  factoryTerritories(query: string, input: any) {
    this.orderType != 3 ? this.findTerritriesFromView(query, input) : this.searchTerritories(query, input);
  }
}
