export class OrderHistory {
  warehouse: string;
  type: string;
  sw: string;
  number: number;
  statusId: number;
  statusName: string;
  dateTime: string;

  public constructor() {
    this.warehouse = '';
    this.type = '';
    this.sw = '';
    this.number = 0;
    this.statusId = 0;
    this.statusName = '';
    this.dateTime = '';
  }
}
