
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { Prop } from 'vue-property-decorator';
import { StateManager } from '@/core/statemanager/StateManager';
import { CourierOrder } from '@/courier/domain/CourierOrder/CourierOrder';
import { FindAllTimelineConfigByStatus } from '@/tracking/application/uses_cases/timelineConfig/search/findAll/FindAllTimelineConfigByStatus';
import { RoutesFindByOiriginAndDestination } from '@/settings/application/uses_cases/routes/search/RoutesFindByOiriginAndDestination';
@Component({
  name: 'PricelistOrder'
})
export default class PricelistOrder extends Vue {
  @Inject(TYPES.STATE_MANAGER)
  readonly stateManager!: StateManager;
  @Inject(TYPES.TIMELINECONFIG_TYPE_FINDALL_BY_STATUS)
  readonly findAllActiveTimelineConfig!: FindAllTimelineConfigByStatus;
  @Inject(TYPES.ROUTES_FIND_BY_ORIGIN_AND_DESTINATION)
  readonly findRouteByOriginAndDestination!: RoutesFindByOiriginAndDestination;

  //DATA

  //PROPS
  @Prop() readonly form!: CourierOrder;
  @Prop() readonly selectedOrigin!: any;
  @Prop() readonly selectedDestination!: any;
  @Prop() readonly pricingLevel!: string;
  //GETTERS
  get fields() {
    return [
      {
        label: 'Proveedor',
        key: 'entity.fullName',
        sortable: true
      },
      {
        label: 'Transport',
        key: 'transportName',
        sortable: true
      },
      {
        label: 'Subtotal',
        key: 'calResponse.subTotal',
        sortable: true
      },
      {
        label: 'Acción',
        key: 'action'
      }
    ];
  }

  get packages() {
    return this.form.lines;
  }

  set packages(newValue: any) {
    this.packages = newValue;
  }

  get totalOrder() {
    const totalOrder: any[] = [];
    this.form.lines.map(item => {
      if (item.selectedProvider != null && 'selectedProvider' in item && 'calResponse' in item.selectedProvider) {
        totalOrder.push(item.selectedProvider.calResponse.subTotal);
      } else {
        totalOrder.push(0);
      }
    });

    return totalOrder.reduce((a: any, b: any) => a + b, 0);
  }

  //REFS
  $refs!: {
    pricelist: InstanceType<typeof ValidationObserver>;
  };

  selected: any;

  async verificar() {
    const validator = await this.$refs.pricelist.validate();

    if (!validator) return false;

    try {
      const timelines = await this.findAllActiveTimelineConfig.execute(true);
      const route = await this.findRouteByOriginAndDestination.execute({
        origin: this.selectedOrigin,
        destination: this.selectedDestination,
        level: this.pricingLevel
      });

      if ('error' in route) {
        this.$swal({ title: `${this.$t('general.warning')}`, text: `${this.$t('general.noRoutes')}`, icon: 'warning' });
        return false;
      }

      const taxes: any[] = [];
      const totalOrder: any[] = [];
      this.form.lines = this.form.lines.map(item => {
        if (item.selectedProvider != null) {
          this.form.lines.map(item => {
            item.selectedProvider.calResponse.taxes.map((item: any) => taxes.push(item.taxPercentage));
          });
          totalOrder.push(item.selectedProvider.calResponse.subTotal);
          const timeline = this.searchTimeline(route.id, item.selectedProvider.transportId, timelines);
          return {
            ...item,
            timelineSkeletonId: timeline.length > 0 ? timeline[0].id : 0
          };
        } else {
          return item;
        }
      });

      this.form.tax = taxes.reduce((a: any, b: any) => a + b, 0);
      this.form.total = totalOrder.reduce((a: any, b: any) => a + b, 0);

      return true;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  searchTimeline(routeId: number, transportId: number, timelines: any[]): any[] {
    try {
      return timelines.filter(item => {
        return item.routeId == routeId && item.typeTransport == transportId && item.active != false;
      });
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
