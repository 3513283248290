
import { TYPES } from '@/core/config/Types';
import { Volume } from '@/settings/domain/volume/Volume';
import { Weight } from '@/settings/domain/weight/Weight';
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { OrderLine } from '@/courier/domain/orderline/OrderLine';
import { StateManager } from '@/core/statemanager/StateManager';
import { CourierOrder } from '@/courier/domain/CourierOrder/CourierOrder';
import { CargoTypeEnum } from '@/courier/domain/CourierOrder/CargoTypeEnum';
import { PurchaseScraping } from '@/courier/application/uses_cases/purchase/search/PurchaseScraping';
import { CalculatePricesCourierOrder } from '@/courier/application/uses_cases/courierOrder/calculatePrices/CalculatePricesCourierOrder';
@Component({
  name: 'CalculateServiceN'
})
export default class CalculateServiceN extends Vue {
  @Inject(TYPES.STATE_MANAGER)
  readonly stateManager!: StateManager;
  @Inject(TYPES.PURCHASE_SCRAPING) readonly purchaseScraping!: PurchaseScraping;
  @Inject(TYPES.COURIER_ORDER_CALCULATE_PRICING) readonly calculate!: CalculatePricesCourierOrder;

  isLoading = false;
  //REFS
  $refs!: {
    modalPackageOption: HTMLFormElement;
    modalOrderQuotation: HTMLFormElement;
    twoStep: InstanceType<typeof ValidationObserver>;
  };
  //PROPS
  @Prop() readonly orderType!: number;
  @Prop({ type: Array, required: true }) readonly dataCompany!: any;
  @Prop({ type: Function, required: true }) factorSelected!: any;
  @Prop({ type: CourierOrder, required: true }) form!: CourierOrder;
  //DATA
  selectedVolume: Volume | null = null;
  selectedWeight: Weight | null = null;
  packageOptions: CargoTypeEnum = CargoTypeEnum.package;
  selectedStore: any | null = { name: 'AMAZON', code: '001' };
  calculatedPackages: any[] = [];
  //GETTERS
  get iconForm() {
    return `${this.form.cargoType == CargoTypeEnum.package ? 'box' : 'file-earmark'}`;
  }
  get volumeList() {
    return this.stateManager.state.dataVolume;
  }

  get weightList() {
    return this.stateManager.state.dataWeight;
  }
  get commodityList() {
    return this.stateManager.state.dataCommodityTypes;
  }
  get packagesNumber() {
    return this.form.lines.length;
  }

  get totalWeight() {
    let suma = 0;
    this.form.lines.map(item => {
      if (item.weight != null && typeof item.weight == 'string') {
        suma += parseFloat(item.weight);
      }
    });
    return suma;
  }

  get totalVolume() {
    let suma = 0;
    this.form.lines.map(item => {
      if (
        item.width != null &&
        item.lengthy != null &&
        item.height != null &&
        typeof item.width == 'string' &&
        typeof item.lengthy == 'string' &&
        typeof item.height == 'string'
      ) {
        suma += parseFloat(item.width) * parseFloat(item.lengthy) * parseFloat(item.height);
      }
    });

    return suma.toFixed(2);
  }

  get stores() {
    return [
      { name: 'AMAZON', code: '001' },
      { name: this.$t('general.allstores'), code: '002' }
    ];
  }

  get verifyCargoType() {
    if (this.form.cargoType != CargoTypeEnum.none) return true;
    return false;
  }
  //COMPONENT LIFECYCLE

  //FUNCTIONS
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  addPackages() {
    this.$refs.twoStep.validate().then((res: boolean) => {
      if (!res) return;
      this.form.lines.push(new OrderLine());
    });
  }

  deletePackages(index: number) {
    this.form.lines.splice(index, 1);
  }

  handdleChangeArray(input: any) {
    this.form.cargoType = input;
    this.form.lines = [];
  }

  async calculatePricing() {
    try {
      if (!(await this.$refs.twoStep.validate()) || this.form.lines.length < 1) return false;

      if (this.form.cargoType == 2) {
        this.form.lines = this.form.lines.map((item, index) => {
          item.description = `${this.$t('general.document')} ${index + 1}`;
          return item;
        });
      }

      this.cleanLastPricingLines();
      const packagePricelists = this.setCalculablePricelists();

      let noCalculablePackage = true;
      let noCalculablePackageString = '';
      packagePricelists.forEach((element: any, index) => {
        if (element.weightPriceLists.length < 1 && element.volumePriceLists.length < 1) {
          noCalculablePackage = false;
          noCalculablePackageString += `${this.$t('general.package')} ${index + 1}: ${element.description}<br>`;
        }
      });

      if (!noCalculablePackage && this.orderType != 3) {
        this.$swal({
          title: `${this.$t('general.warning')}`,
          text: `${this.$t(`general.nopricelist`)}`,
          icon: 'warning',
          footer: `<span class="text-danger">${noCalculablePackageString}</span>`
        });
        return false;
      } else {
        const res = await this.calculatePackagesPrices(packagePricelists);
        if (!('error' in res)) {
          this.form.lines = res.length < 1 && this.orderType == 3 ? this.form.lines : res;
          let noPricelist = false;
          this.form.lines.forEach((element: any) => {
            if (element.calculatedBy != null && element.priceListCalc && element.priceListCalc.length < 1) {
              this.$swal(
                `${this.$t('general.warning')}`,
                `${this.$t(`general.nopricelist${element.calculatedBy}`)}`,
                'warning'
              );
              noPricelist = true;
              return;
            }
          });

          return !noPricelist || this.orderType != 3;
        }
      }
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  verCosas(cosa: any) {
    // eslint-disable-next-line no-console
    console.log(cosa);
  }

  secondaryWindow(url: any) {
    const w = screen.width;
    const h = screen.height;
    const leftPosition = (screen.width - w - 12) / 2;
    const topPosition = (screen.height - h - 57) / 2;
    const params = `width=${w},height=${h},left=-${leftPosition}pt,top=${topPosition}pt,
      scrollbars=no,resizable=no`;
    const newWindow: any = window.open(url, 'Amazon virtual Store', params);
    // Puts focus on the newWindow
    if (window) newWindow.focus();
  }

  getDataAmazonUrl(index: any) {
    this.isLoading = true;
    if (this.form.lines[index].purchaseUrl.length > 0) {
      this.purchaseScraping
        .execute(this.form.lines[index].purchaseUrl)
        .then((response: any) => {
          // eslint-disable-next-line no-console
          this.form.lines[index].description = response.title;
          this.form.lines[index].weight = response.weight.split(' ')[0].replace(/[\u200E]/g, '');
          this.isLoading = false;
          this.$swal({
            title: `${this.$t('general.warning')}`,
            text: `${this.$t('general.unitsWarning')}`,
            icon: 'warning',
            footer: `<strong class="text-danger">${this.$t('general.weightin')}: ${
              response.measurementUnit
            } - ${this.$t('general.volume')}: ${response.weight.split(' ')[1]}</strong>`
          });
        })
        .catch(error => {
          this.isLoading = false;
          this.$swal('Error', `${this.$t('general.messageamazon')}`, 'error');
          throw new Error(`${error}`);
        });
    }
  }

  async calculatePackagesPrices(packages: any) {
    const res = await this.calculate.execute(packages);
    return res;
  }

  decodeUnicode(unicode: string) {
    return decodeURIComponent(JSON.parse(`"${unicode}"`));
  }

  cleanLastPricingLines() {
    this.form.lines = this.form.lines.map((item: any) => {
      if ('priceListCalc' in item) {
        delete item.priceListCalc;
      }
      if ('volumePriceLists' in item) {
        delete item.volumePriceLists;
      }
      if ('weightPriceLists' in item) {
        delete item.weightPriceLists;
      }
      return item;
    });
  }

  setCalculablePricelists() {
    return this.form.lines.map((item: any) => {
      item = {
        ...item,
        volumePriceLists: this.stateManager.state.dataPriceList
          .map(pricelist => {
            return this.orderType != 2 &&
            this.form.cargoType == 1 &&
            pricelist.commodity?.id === item.commodity.id && //VERIFICAMOS EL COMMODITY ES EL CORRECTO
            pricelist.volumeUnitCode === this.selectedVolume?.code && //VERIFICAMOS SI LA LISTA DE PRECIOS ESTA DADA EN VOLUMEN
              pricelist.weightUnitCode === this.selectedWeight?.code &&
              ((item.weight >= pricelist.slidingScaleFrom && item.weight <= pricelist.slidingScaleTo) || //VERIFICAMOS SI EL PESO DEL PRODUCTO ESTÁ EN LA ESCALA
                pricelist.slidingScaleTo === 0) // O SI ES UNA SOLA ESCALA PARA TODO
              ? pricelist.id
              : null;
          })
          .filter(element => element !== null),
        weightPriceLists: this.stateManager.state.dataPriceList
          .map(pricelist => {
            return (this.form.cargoType == 2 &&
            pricelist.volumeUnitCode === null &&
            pricelist.weightUnitCode === this.selectedWeight?.code && //VERIFICAMOS SI LA LISTA DE PRECIOS ES SOLO POR PESO
              ((item.weight >= pricelist.slidingScaleFrom && item.weight <= pricelist.slidingScaleTo) || //VERIFICAMOS SI EL PESO DEL PRODUCTO ESTÁ EN LA ESCALA
                pricelist.slidingScaleTo === 0)) ||
              (this.form.cargoType == 1 &&
              pricelist.commodity?.id === item.commodity.id && //VERIFICAMOS EL COMMODITY ES EL CORRECTO
              pricelist.volumeUnitCode === null &&
              pricelist.weightUnitCode === this.selectedWeight?.code && //VERIFICAMOS SI LA LISTA DE PRECIOS ES SOLO POR PESO
                ((item.weight >= pricelist.slidingScaleFrom && item.weight <= pricelist.slidingScaleTo) || //VERIFICAMOS SI EL PESO DEL PRODUCTO ESTÁ EN LA ESCALA
                  pricelist.slidingScaleTo === 0)) // O SI ES UNA SOLA ESCALA PARA TODO
              ? pricelist.id
              : null;
          })
          .filter(element => element !== null)
      };
      return item;
    });
  }
}
