import { Warehouse } from '@/settings/domain/warehouse/Warehouse';

export class Store {
  public storeId: number;
  public storeName: string;
  public warehouse: Warehouse;
  public active: boolean;
  public forBondedEntry: boolean;

  public constructor() {
    this.storeId = 0;
    this.storeName = '';
    this.warehouse = new Warehouse();
    this.active = true;
    this.forBondedEntry = false;
  }
}
