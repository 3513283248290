export class OrderRate {
  id: number;
  rateTaxCode: string;
  rateTaxName: string;
  rateTaxPercentage: number;

  public constructor() {
    this.id = 0;
    this.rateTaxCode = '';
    this.rateTaxName = '';
    this.rateTaxPercentage = 0;
  }
}
