import { AddressLine } from '@/settings/domain/address/AddressLine';

export class OrderDestination {
  typeOrder: string;
  warehouse: string;
  number: number;
  sw: string;
  id: number;
  customerId: string;
  fullName: string;
  phone1: string;
  phone2: string;
  email: string;
  addressLine: AddressLine;

  public constructor() {
    this.typeOrder = '';
    this.warehouse = '';
    this.number = 0;
    this.sw = '';
    this.id = 0;
    this.customerId = '';
    this.fullName = '';
    this.phone1 = '';
    this.phone2 = '';
    this.email = '';
    this.addressLine = new AddressLine();
  }
}
